// Imports
import React from 'react';
import styled from 'styled-components/macro';
import H2 from './h2';
import Paragraph from './paragraph';


// Styled components
const Container = styled.div`
	width: 90%;
	max-width: 46.875rem;
	margin: 4.5rem auto 0 auto;
`;

const GradientH2 = styled(H2)`
	background: linear-gradient(90deg, #acc734 0%, #6eb32e 100%);
	-webkit-background-clip: text; // LATER: Remove when we upgrade to styled-components v6
	-webkit-text-fill-color: transparent; // LATER: Remove when we upgrade to styled-components v6
	background-clip: text;
	text-fill-color: transparent;
`;


// Function component
const UpTo: React.FC = () => {
	return (
		<Container>
			<GradientH2>
				What we’re <em>up to</em>…
			</GradientH2>
			
			<Paragraph>
				We build software companies from the ground up and operate them for the long term. We’re currently focused on{' '}
				<a href='https://www.hoa-express.com/' target='_blank' rel='noopener noreferrer'>
					HOA Express
				</a>{' '}
				and{' '}
				<a href='https://gradepro.app/' target='_blank' rel='noopener noreferrer'>
					GradePro
				</a>
				. We also support the software community by publishing meaningful{' '}
				<a href='https://github.com/sparksuite' target='_blank' rel='noopener noreferrer'>
					open source projects
				</a>{' '}
				and{' '}
				<a href='https://www.meetup.com/freecodecamp-houston-tx/' target='_blank' rel='noopener noreferrer'>
					hosting meetups
				</a>{' '}
				at our HQ.
			</Paragraph>
		</Container>
	);
};

export default UpTo;
