// Imports
import { createGlobalStyle } from 'styled-components/macro';


// Define lobal style
const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		padding: 0;
		font-family: Work Sans, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		text-size-adjust: 100%;
		font-variant-numeric: lining-nums;
		font-feature-settings:"lnum" 1;
		min-height: 100%;
		background: #fff;
		color: #000;
		line-height: 1.2;
	}
	
	*:not(.svg-inline--fa) {
		box-sizing: border-box;
	}
`;

export default GlobalStyle;
