// Imports
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import H2 from './h2';
import Paragraph from './paragraph';


// Styled components
const Container = styled.div`
	width: 90%;
	max-width: 46.875rem;
	margin: 3.75rem auto 0 auto;
`;

const GradientH2 = styled(H2)`
	background: linear-gradient(90deg, #e35c36 0%, #e3364b 100%);
	-webkit-background-clip: text; // LATER: Remove when we upgrade to styled-components v6
	-webkit-text-fill-color: transparent; // LATER: Remove when we upgrade to styled-components v6
	background-clip: text;
	text-fill-color: transparent;
`;

const Openings = styled.span`
	display: block;
	
	span {
		display: block;
		line-height: 0.9;
		margin-top: 0.75rem;
	}
	
	span a {
		font-size: 80%;
	}
`;

const SubscribeForm = styled.form`
	margin: 0.5em auto 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5em;
	max-width: 500px;
	
	input {
		flex-grow: 1;
		min-width: 0;
		appearance: none;
		background: #fff;
		border: 0.15rem solid #000;
		border-radius: 0;
		font-size: 1.2em;
		font-weight: 400;
		font-family: inherit;
		color: #000;
		line-height: 1.2;
		margin: 0;
		padding: 0.3em 0.4em;
		transition: all 0.3s ease;
		
		&:focus {
			outline: none;
			border-color: #acd561;
		}
	}
	
	button {
		flex-grow: 0;
		appearance: none;
		border: 0;
		background: #acd561;
		cursor: pointer;
		font-size: 1.2em;
		font-weight: 400;
		font-family: inherit;
		color: #000;
		line-height: 1.2;
		margin: 0;
		padding: 0.3em 0.7em;
		border: 0.15rem solid #acd561;
		transition: all 0.15s ease;
		
		&:hover {
			background: #bfeb6f;
			border-color: #bfeb6f;
		}
		
		@media (max-width: calc(1rem * 25)) {
			padding-left: 0.4em;
			padding-right: 0.4em;
		}
	}
`;


// Define the accepted props
interface Props {
	storybookOpeningsHTML?: string;
}


// Function component
const Join: React.FC<Props> = ({ storybookOpeningsHTML }) => {
	// Use state
	const [openingsHTML, setOpeningsHTML] = useState(storybookOpeningsHTML ?? '');
	
	
	// Retrieve job openings from Homerun on mount
	useEffect(() => {
		if (storybookOpeningsHTML !== undefined) {
			return;
		}
		
		fetch(`https://embed.homerun.co/koyy54rstoempoxl2env/widget-en.html?t=${new Date().getTime()}`)
			.then((response) => {
				if (response.status !== 200) {
					return;
				}
				
				response.text().then((text) => setOpeningsHTML(text));
			})
			.catch(() => {
				// Do nothing
			});
	}, [setOpeningsHTML, storybookOpeningsHTML]);
	
	
	// Parse the HTML
	const parser = new DOMParser();
	const document = parser.parseFromString(openingsHTML, 'text/html');
	const openings = document.getElementsByClassName('homerun-widget__list-item');
	
	
	// Loop over each and build JSX
	const openingsJSX: React.ReactElement[] = [];
	
	for (const opening of openings) {
		openingsJSX.push(
			<span key={opening.getElementsByTagName('a')[0].getAttribute('href') ?? '#'}>
				<a
					href={opening.getElementsByTagName('a')[0].getAttribute('href') ?? '#'}
					target='_blank'
					rel='noopener noreferrer'
				>
					{opening.getElementsByClassName('homerun-widget__vacancy__title')[0].innerHTML}
				</a>
			</span>
		);
	}
	
	
	// Return JSX
	return (
		<Container>
			<GradientH2 id='join'>
				Looking to <em>join</em>?
			</GradientH2>
			
			<Paragraph>
				Hiring across our portfolio is consolidated through Sparksuite.{' '}
				{!openingsHTML || openingsHTML.includes('no job openings') ? (
					'Currently, there are no job openings.'
				) : (
					<React.Fragment>
						Our current openings:
						<Openings>{openingsJSX}</Openings>
					</React.Fragment>
				)}
			</Paragraph>
			
			<Paragraph style={{ marginTop: '1em', fontSize: '1.5em', color: '#4e4e4e' }}>
				Want to be notified about future job openings? Subscribe below.{' '}
				<em>We won’t use your email address for anything else.</em>
			</Paragraph>
			
			<SubscribeForm
				action='https://sparksuite.us18.list-manage.com/subscribe/post?u=fce538d50c2064424b227cffc&amp;id=f1d6554a6e'
				method='post'
				target='_blank'
				noValidate
			>
				<div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
					<input type='text' name='b_fce538d50c2064424b227cffc_f1d6554a6e' tabIndex={-1} defaultValue='' />
				</div>
				
				<input
					type='email'
					name='EMAIL'
					id='mce-EMAIL'
					placeholder='Your email address'
					aria-label='Your email address'
				/>
				
				<button type='submit' name='subscribe'>
					Subscribe
				</button>
			</SubscribeForm>
		</Container>
	);
};

export default Join;
