// Imports
import React from 'react';
import styled from 'styled-components/macro';
import Gallery from './gallery';
import H2 from './h2';
import Paragraph from './paragraph';


// Styled components
const Container = styled.div`
	width: 100%;
	margin: 3.75rem 0 0 0;
`;

const GradientH2 = styled(H2)`
	max-width: 90%;
	background: linear-gradient(90deg, #38b1c9 0%, #3171b0 100%);
	-webkit-background-clip: text; // LATER: Remove when we upgrade to styled-components v6
	-webkit-text-fill-color: transparent; // LATER: Remove when we upgrade to styled-components v6
	background-clip: text;
	text-fill-color: transparent;
`;

const SmallerParagraph = styled(Paragraph)`
	font-size: 1.5em;
	color: #4e4e4e;
	padding-top: 2rem;
`;


// Function component
const Humans: React.FC = () => {
	return (
		<Container>
			<GradientH2>
				The <em>humans</em> behind our products…
			</GradientH2>
			
			<Gallery>
				<img
					src='images/gallery/photo1.webp?cache-key=v2'
					alt='HOA Express celebrating the launch of the Admin Portal'
				/>
				<img src='images/gallery/photo2.webp?cache-key=v2' alt='Team outing throwing pottery' />
				<img src='images/gallery/photo3.webp?cache-key=v2' alt='Team outing at Topgolf' />
				<img src='images/gallery/photo4.webp?cache-key=v2' alt='Quarterly all-hands meeting' />
				<img src='images/gallery/photo5.webp?cache-key=v2' alt='Team outing at bowling alley' />
				<img src='images/gallery/photo6.webp?cache-key=v2' alt='Engineering team meeting' />
				<img src='images/gallery/photo7.webp?cache-key=v3' alt='Volunteering at Houston Food Bank' />
				<img
					src='images/gallery/photo8.webp?cache-key=v2'
					alt='Team members celebrating work anniversaries with company swag'
				/>
				<img src='images/gallery/photo9.webp?cache-key=v2' alt='Team outing at disc golf park (candid)' />
				<img src='images/gallery/photo10.webp?cache-key=v2' alt='Team outing at disc golf park (group)' />
				<img src='images/gallery/photo11.webp?cache-key=v2' alt='Free-lunch Friday pizza' />
				<img src='images/gallery/photo12.webp?cache-key=v2' alt='Team members playing cornhole' />
			</Gallery>
			
			<SmallerParagraph>
				See more on Instagram{' '}
				<a href='https://www.instagram.com/sparksuitehq/' target='_blank' rel='noopener noreferrer'>
					@sparksuitehq
				</a>
			</SmallerParagraph>
		</Container>
	);
};

export default Humans;
