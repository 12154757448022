// Imports
import React from 'react';
import styled from 'styled-components/macro';


// Styled components
const Container = styled.svg`
	position: absolute;
	bottom: -1px;
	min-width: 100%;
	
	@media (max-width: calc(1rem * 50)) {
		width: 200%;
	}
`;


// Define the accepted props
type Props = React.ComponentProps<typeof Container>;


// Function component
const Waves: React.FC<Props> = (props) => (
	<Container
		clip-rule='evenodd'
		fill-rule='evenodd'
		stroke-linejoin='round'
		stroke-miterlimit='2'
		viewBox='0 0 1200 94'
		height='94'
		preserveAspectRatio='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g fill='#fff' fillRule='nonzero'>
			<path d='m1200 4.517c-18.32 16.918-58.48 44.099-104.63 45.543-63.19 1.976-85.56 42.156-184.288 40.18-98.725-1.977-65.817-79.042-176.388-55.988-110.572 23.053-82.929 40.179-217.194 40.179s-110.571-40.838-239.571-42.156c-110.114-1.124-151.173 16.949-277.929 42.342v19.383h1200z' />
			<path
				d='m0 77.532c79.503-13.679 58.306-84.752 169.918-60.921 121.715 25.988 91.286 45.293 239.082 45.293s121.714-46.036 263.714-47.521 179.674 26.731 386.876 63.114c46.63 8.188 94.36 12.572 140.41 14.353v2.15h-1200z'
				fillOpacity='.5'
			/>
		</g>
	</Container>
);

export default Waves;
