// Imports
import styled from 'styled-components/macro';


// Styled component
const H2 = styled.h2`
	width: auto;
	margin 0 auto;
	padding: 0 0 1.5rem 0;
	font-family: 'Neuton';
	font-weight: 400;
	font-size: 3.75em;
	line-height: 1;
	text-align: center;
	display: inline-block;
	
	@media (max-width: calc(1rem * 30)) {
		font-size: 3em;
	}
`;

export default H2;
