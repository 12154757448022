// Imports
import React from 'react';
import styled from 'styled-components/macro';


// Styled components
const Container = styled.svg`
	width: 100%;
	max-width: min(300px, 33vw);
	
	@media (max-width: calc(1rem * 50)) {
		max-width: min(250px, 80vw);
	}
`;


// Define the accepted props
type Props = React.ComponentProps<typeof Container>;


// Function component
const Logo: React.FC<Props> = (props) => (
	<Container
		clip-rule='evenodd'
		fill-rule='evenodd'
		stroke-linejoin='round'
		stroke-miterlimit='2'
		viewBox='0 0 2000 236'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g fillRule='nonzero'>
			<path
				d='m.449-.631-.047.06c-.005-.005-.012-.011-.021-.018-.009-.006-.018-.012-.029-.017s-.024-.01-.037-.014c-.014-.003-.029-.005-.044-.005-.039 0-.067.011-.086.033s-.028.048-.028.078c0 .017.003.031.01.042.006.011.014.021.025.028.01.008.022.015.035.02s.026.01.04.015c.022.008.046.017.071.028s.049.025.071.042c.021.017.039.038.054.063.014.025.021.054.021.089 0 .03-.006.057-.016.081-.012.024-.026.044-.045.061-.019.018-.042.031-.067.039-.025.01-.052.014-.08.014-.025 0-.05-.003-.074-.011-.024-.007-.046-.016-.066-.027s-.038-.023-.053-.036c-.016-.013-.028-.027-.037-.04l.052-.052c.009.009.019.019.031.029.012.011.025.02.039.029s.029.016.046.021.035.008.054.008c.017 0 .034-.003.05-.008s.03-.012.043-.022c.012-.009.022-.021.029-.036s.011-.032.011-.051c0-.027-.006-.048-.019-.065s-.029-.031-.048-.042-.039-.019-.06-.026-.039-.013-.054-.019c-.012-.005-.026-.011-.043-.019s-.032-.019-.047-.033c-.015-.013-.028-.03-.039-.051-.011-.02-.016-.045-.017-.074-.001-.022.002-.044.009-.066s.018-.042.033-.06.036-.033.061-.044.056-.017.093-.017c.027 0 .054.005.083.014s.061.029.096.059z'
				transform='matrix(243.2185 0 0 243.2185 246.880840322 206.044691212)'
			/>
			<path
				d='m.141-.392h.087c.029 0 .054-.004.073-.012.019-.007.034-.017.045-.028s.019-.024.024-.038.007-.027.007-.04c0-.015-.004-.029-.011-.043-.008-.013-.019-.025-.031-.036-.013-.01-.029-.018-.047-.024-.019-.006-.038-.009-.059-.009h-.088zm-.083.392v-.7h.165c.023 0 .048.003.076.01.027.007.053.019.077.034s.044.035.06.059.024.053.024.087c0 .061-.018.109-.054.144-.036.036-.088.054-.156.054h-.109v.312z'
				transform='matrix(243.2185 0 0 243.2185 430.754026322 206.044691212)'
			/>
			<path
				d='m.38-.515-.009-.033c-.009.033-.021.066-.035.097s-.028.063-.041.095h.152c-.011-.027-.022-.053-.033-.08-.011-.026-.023-.052-.034-.079zm.301.515h-.083c-.019-.046-.039-.092-.058-.139-.02-.046-.04-.092-.059-.138h-.22c-.019.046-.039.092-.058.138-.02.047-.04.093-.059.139h-.083c.052-.123.104-.245.155-.366s.103-.243.155-.366c.052.123.104.245.155.366s.103.243.155.366z'
				transform='matrix(243.2185 0 0 243.2185 596.629043322 206.044691212)'
			/>
			<path
				d='m.141-.392h.087c.029 0 .054-.004.073-.012.019-.007.034-.017.045-.028s.019-.024.024-.038.007-.027.007-.04c0-.015-.004-.029-.011-.043-.008-.013-.019-.025-.031-.036-.013-.01-.029-.018-.047-.024-.019-.006-.038-.009-.059-.009h-.088zm-.083.392v-.7h.165c.023 0 .048.003.076.01.027.007.053.019.077.034s.044.035.06.059.024.053.024.087c0 .05-.012.092-.037.125-.026.034-.062.056-.11.067l.15.318h-.09l-.147-.312h-.085v.312z'
				transform='matrix(243.2185 0 0 243.2185 823.5517092472 206.044691212)'
			/>
			<path
				d='m.581 0h-.099c-.048-.061-.096-.121-.143-.182-.047-.06-.095-.12-.143-.181l-.062.059v.304h-.083v-.7h.083v.274l-.003.032.019-.023c.05-.047.099-.094.149-.142.048-.047.098-.094.148-.141h.102c-.05.048-.1.095-.149.142s-.099.094-.149.142c.055.07.11.139.165.208s.11.138.165.208z'
				transform='matrix(243.2185 0 0 243.2185 996.723475822 206.044691212)'
			/>
			<path
				d='m.449-.631-.047.06c-.005-.005-.012-.011-.021-.018-.009-.006-.018-.012-.029-.017s-.024-.01-.037-.014c-.014-.003-.029-.005-.044-.005-.039 0-.067.011-.086.033s-.028.048-.028.078c0 .017.003.031.01.042.006.011.014.021.025.028.01.008.022.015.035.02s.026.01.04.015c.022.008.046.017.071.028s.049.025.071.042c.021.017.039.038.054.063.014.025.021.054.021.089 0 .03-.006.057-.016.081-.012.024-.026.044-.045.061-.019.018-.042.031-.067.039-.025.01-.052.014-.08.014-.025 0-.05-.003-.074-.011-.024-.007-.046-.016-.066-.027s-.038-.023-.053-.036c-.016-.013-.028-.027-.037-.04l.052-.052c.009.009.019.019.031.029.012.011.025.02.039.029s.029.016.046.021.035.008.054.008c.017 0 .034-.003.05-.008s.03-.012.043-.022c.012-.009.022-.021.029-.036s.011-.032.011-.051c0-.027-.006-.048-.019-.065s-.029-.031-.048-.042-.039-.019-.06-.026-.039-.013-.054-.019c-.012-.005-.026-.011-.043-.019s-.032-.019-.047-.033c-.015-.013-.028-.03-.039-.051-.011-.02-.016-.045-.017-.074-.001-.022.002-.044.009-.066s.018-.042.033-.06.036-.033.061-.044.056-.017.093-.017c.027 0 .054.005.083.014s.061.029.096.059z'
				transform='matrix(243.2185 0 0 243.2185 1189.595746322 206.044691212)'
			/>
			<path
				d='m.328.008c-.036 0-.069-.005-.1-.015s-.057-.025-.08-.045c-.023-.019-.04-.044-.053-.073s-.019-.063-.019-.101v-.474h.083v.471c0 .025.004.048.013.067.008.02.02.037.035.05s.032.023.053.03.043.011.068.011.047-.004.068-.011.039-.017.054-.03c.014-.013.026-.03.035-.05.009-.019.013-.042.013-.067v-.471h.083v.474c0 .038-.006.072-.019.101s-.031.054-.054.073c-.023.02-.049.035-.08.045s-.064.015-.1.015z'
				transform='matrix(243.2185 0 0 243.2185 1373.468932322 206.044691212)'
			/>
			<path d='m1603.067196322 35.791741212h20.1871355v170.25295h-20.1871355z' strokeWidth='243.2185' />
			<path
				d='m.553-.7v.079h-.234v.621h-.083v-.621h-.236v-.079z'
				transform='matrix(243.2185 0 0 243.2185 1693.544478322 206.044691212)'
			/>
			<path
				d='m.069 0v-.7h.431v.079h-.348v.229h.309v.079h-.309v.234h.362v.079z'
				transform='matrix(243.2185 0 0 243.2185 1874.9825607 206.044691212)'
			/>
		</g>
		<g transform='translate(-50 -50)'>
			<path
				d='m184.985 270h-134.985v16h134.985zm-16-110v109.998h16v-109.998h.015v-16h-103v16zm-31.985 77.996h-87v16h103v-77.996h-87v-110h118.985v-16h-134.985v142h87zm-39-139.996h-16v45.996h16zm87-16h-103v16h103z'
				fill='#67b1d6'
			/>
			<path
				d='m184.985 270h-134.985v16h134.985zm-16-17.612v17.61h16v-10.411zm-31.985-14.392h-87v16h103v-8.801z'
				fill='#e46b65'
			/>
			<path
				d='m76.869 176-26.869 16h87v45.996s16 7.199 16 7.199v-69.195zm26.869-16h65.247v92.388l16 7.199v-99.587h.015v-16h-54.393z'
				fill='#67b1d6'
			/>
			<path
				d='m50 192 26.869-16h-10.869v-110h118.985v-16h-134.985zm53.738-32 26.869-16h-48.607v16zm-5.738-62h-16v45.996h16zm87-16h-103v16h103z'
				fill='#acd561'
			/>
		</g>
	</Container>
);

export default Logo;
