// Imports
import styled from 'styled-components/macro';


// Styled component
const Paragraph = styled.p`
	margin: 0;
	padding: 0;
	font-size: 1.875em;
	font-weight: 300;
	line-height: 1.2;
	
	a {
		color: #5a9dbd; // These have been darkened slightly to meet contrast requirement
		text-decoration: none;
		border-bottom: 0.05em solid transparent;
		transition: border-bottom 0.1s ease;
		
		&:hover {
			border-bottom: 0.05em solid #5a9dbd; // These have been darkened slightly to meet contrast requirement
		}
	}
	
	@media (max-width: calc(1rem * 30)) {
		font-size: 1.5em;
	}
`;

export default Paragraph;
