// Imports
import React from 'react';
import styled from 'styled-components/macro';
import Logo from './svgs/logo';


// Styled components
const Container = styled.footer`
	width: 100%;
	margin-top: 3.75rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #4e4e4e;
	overflow: hidden;
	
	> svg.waves {
		min-width: 100%;
	}
`;

const SmallerLogo = styled(Logo)`
	height: 20px;
	margin-bottom: 0.5em;
`;


// Function component
const Footer: React.FC = () => {
	return (
		<Container>
			<SmallerLogo />
			&copy; {new Date().getFullYear()} Sparksuite, Inc.
			<svg
				viewBox='0 0 1200 114'
				fill='none'
				className='waves'
				height='114'
				preserveAspectRatio='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M0 81.0155C31.7512 67.0155 147.889 30.0155 220.949 30.0155C312.275 30.0155 338.789 65.0155 427.169 72.0155C515.548 79.0155 608.838 36.0155 709.984 41.0155C811.129 46.0155 844.517 97.0155 942.717 101.016C1000.33 102.682 1159.93 82.6155 1200 41.0155'
					stroke='#ACD561'
					strokeWidth='2'
				/>
				<path
					d='M0 93.1113C31.7512 79.1113 147.889 42.1113 220.949 42.1113C312.275 42.1113 338.789 77.1113 427.169 84.1113C515.548 91.1113 608.838 48.1113 709.984 53.1113C811.129 58.1113 844.517 109.111 942.717 113.111C1000.33 114.778 1159.93 94.7113 1200 53.1113'
					stroke='#ACD561'
					strokeOpacity='0.85'
					strokeWidth='2'
				/>
				<path
					d='M0 105.207C31.7512 91.2073 147.889 54.2073 220.949 54.2073C312.275 54.2073 338.789 89.2073 427.169 96.2073C515.548 103.207 608.838 60.2073 709.984 65.2073C811.129 70.2073 844.517 121.207 942.717 125.207C1000.33 126.874 1159.93 106.807 1200 65.2073'
					stroke='#ACD561'
					strokeOpacity='0.7'
					strokeWidth='2'
				/>
				<path
					d='M0 117.303C31.7512 103.303 147.889 66.3031 220.949 66.3031C312.275 66.3031 338.789 101.303 427.169 108.303C515.548 115.303 608.838 72.3031 709.984 77.3031C811.129 82.3031 844.517 133.303 942.717 137.303C1000.33 138.97 1159.93 118.903 1200 77.3031'
					stroke='#ACD561'
					strokeOpacity='0.55'
					strokeWidth='2'
				/>
				<path
					d='M0 129.399C31.7512 115.399 147.889 78.3989 220.949 78.3989C312.275 78.3989 338.789 113.399 427.169 120.399C515.548 127.399 608.838 84.3989 709.984 89.3989C811.129 94.3989 844.517 145.399 942.717 149.399C1000.33 151.066 1159.93 130.999 1200 89.3989'
					stroke='#ACD561'
					strokeOpacity='0.4'
					strokeWidth='2'
				/>
				<path
					d='M0 141.495C31.7512 127.495 147.889 90.4949 220.949 90.4949C312.275 90.4949 338.789 125.495 427.169 132.495C515.548 139.495 608.838 96.4949 709.984 101.495C811.129 106.495 844.517 157.495 942.717 161.495C1000.33 163.162 1159.93 143.095 1200 101.495'
					stroke='#ACD561'
					strokeOpacity='0.25'
					strokeWidth='2'
				/>
				<path
					d='M0 153.591C31.7512 139.591 147.889 102.591 220.949 102.591C312.275 102.591 338.789 137.591 427.169 144.591C515.548 151.591 608.838 108.591 709.984 113.591C811.129 118.591 844.517 169.591 942.717 173.591C1000.33 175.257 1159.93 155.191 1200 113.591'
					stroke='#ACD561'
					strokeOpacity='0.1'
					strokeWidth='2'
				/>
			</svg>
		</Container>
	);
};

export default Footer;
