// Imports
import React from 'react';
import styled from 'styled-components/macro';


// Styled components
const Container = styled.svg`
	width: 20.0625rem;
	position: absolute;
	bottom: 0;
	margin-left: -10.03125rem;
	z-index: 1;
`;


// Define the accepted props
type Props = React.ComponentProps<typeof Container>;


// Function component
const Stroke: React.FC<Props> = (props) => (
	<Container fill='none' viewBox='0 0 321 48' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='m300.208 20.271s-27.657 11.5191-140.084 3.5557c-112.4271-7.9633-140.0833 3.5558-140.0833 3.5558'
			stroke='#fff'
			strokeLinecap='round'
			strokeWidth='40'
		/>
	</Container>
);

export default Stroke;
