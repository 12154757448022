// Imports
import React from 'react';
import GlobalStyle from './global-style';
import Header from './header';
import UpTo from './up-to';
import Humans from './humans';
import Join from './join';
import Footer from './footer';


// Define the accepted props
interface Props {
	storybookOpeningsHTML?: string;
}


// Function component
const App: React.FC<Props> = ({ storybookOpeningsHTML }) => {
	// Return JSX
	return (
		<React.StrictMode>
			<GlobalStyle />
			<Header />
			<main style={{ textAlign: 'center' }}>
				<UpTo />
				<Humans />
				<Join storybookOpeningsHTML={storybookOpeningsHTML} />
			</main>
			<Footer />
		</React.StrictMode>
	);
};

export default App;
