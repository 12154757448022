// Imports
import ReactDOM from 'react-dom/client';
import App from './app';


// Disable Plausible if not in production
if (process.env.REACT_APP__ENVIRONMENT !== 'production') {
	localStorage.setItem('plausible_ignore', 'true');
}


// Create root
const container = document.getElementById('root');

if (!container) {
	throw new Error('Root container should exist');
}

const root = ReactDOM.createRoot(container);


// Mount & render
root.render(<App />);
