// Imports
import React from 'react';
import styled from 'styled-components/macro';
import Waves from './svgs/waves';
import Logo from './svgs/logo';
import Stroke from './svgs/stroke';


// Styled components
const Container = styled.header`
	width: 100%;
	padding-bottom: 9rem;
	position: relative;
	overflow: hidden;
	
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.33;
		z-index: -1;
		background-image: url('../images/colors.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
`;

const TopBar = styled.div`
	width: 90%;
	max-width: 1050px;
	margin: auto;
	padding-top: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	@media (max-width: calc(1rem * 50)) {
		flex-direction: column;
		gap: 0.75em;
	}
`;

const Links = styled.nav`
	display: flex;
	gap: 1.75em;
	
	a {
		color: #000;
		font-size: 1.375em;
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
	
	@media (max-width: calc(1rem * 30)) {
		flex-direction: column;
		gap: 0.15em;
		text-align: center;
	}
`;

const H1 = styled.h1`
	width: 95%;
	margin: 0 auto;
	padding: 0;
	margin-top: 5.75rem;
	text-align: center;
	font-family: Neuton, serif;
	font-weight: 400;
	font-size: 3.75em;
	line-height: 0.9;
`;

const Subheading = styled.div`
	width: 95%;
	max-width: 18.5em;
	margin: 1.375rem auto 0 auto;
	text-align: center;
	font-size: 1.875em;
	font-weight: 300;
	line-height: 1.173;
	position: relative;
	
	> p {
		margin: 0;
		padding: 0 0 0.3em 0;
		position: relative;
		z-index: 2;
	}
`;


// Function component
const Header: React.FC = () => {
	return (
		<Container>
			<TopBar>
				<Logo />
				
				<Links>
					<a href='https://blog.sparksuite.com/' target='_blank' rel='noopener noreferrer'>
						Blog
					</a>
					
					<a href='https://handbook.sparksuite.com/' target='_blank' rel='noopener noreferrer'>
						Handbook
					</a>
					
					<a href='https://salaries.sparksuite.com/' target='_blank' rel='noopener noreferrer'>
						Salary Calculator
					</a>
				</Links>
			</TopBar>
			
			<H1>
				A <em>family</em> of software companies
			</H1>
			
			<Subheading>
				<p>
					Built and supported by extraordinary people who are passionate about
					<br />
					mighty fine software
				</p>
				
				<Stroke />
			</Subheading>
			
			<Waves />
		</Container>
	);
};

export default Header;
