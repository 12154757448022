// Imports
import React from 'react';
import styled from 'styled-components/macro';


// Styled components
const Container = styled.div`
	width: 100%;
	overflow: hidden;
`;

interface SliderProps {
	count: number;
}

const Slider = styled.div<SliderProps>`
	@keyframes scroll {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-292px * ${(props) => props.count}));
		}
	}
	
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(252px * ${(props) => props.count * 2});
	gap: 40px;
	padding-bottom: 5px; // Prevents clipping the drop shadow
	
	img {
		width: 252px;
		height: 315px;
		
		
		// Prevents super glitchy rendering on Safari; but, it causes inconsistent screenshots in stories
		${(window as typeof window & { STORYBOOK?: true }).STORYBOOK ? '' : '-webkit-transform: translateZ(0);'}
		
		&:nth-child(3n + 1) {
			filter: drop-shadow(5px 5px 0px #acd561);
		}
		
		&:nth-child(3n + 2) {
			filter: drop-shadow(5px 5px 0px #67b1d6);
			margin-top: 80px;
		}
		
		&:nth-child(3n) {
			filter: drop-shadow(5px 5px 0px #e46b65);
			margin-top: 40px;
		}
	}
`;


// Function component
const Gallery: React.FC<React.PropsWithChildren> = ({ children }) => {
	if (!Array.isArray(children)) {
		throw new Error('Children must be an array');
	}
	
	if (children.length % 3 !== 0) {
		throw new Error('The number of images must be a multiple of 3 (otherwise drop shadow colors change mid-scroll)');
	}
	
	return (
		<Container>
			<Slider count={children.length}>
				{children}
				{children}
			</Slider>
		</Container>
	);
};

export default Gallery;
